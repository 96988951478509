@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}
html {
	scroll-behavior: smooth;
  }
  

:root {
	--mainColor: #29335c;
	--mainColorLight: #5767aa;
	--secondaryColor: #ff7300;
	--textColor: #eee;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 3rem;
    margin: 0; /* Set margin to 0 to avoid extra space */
    background-color: white;
    color: rgb(0, 0, 0);
    width: 100%;
	height: 120px;
    position: fixed;
    top: 0; /* Make sure it's fixed to the top */
    z-index: 1000; /* Ensure the header stays above other content */
	
}

nav a {
	margin: 0 1rem;
    font-size: 18px;
	color: rgb(0, 0, 0);
	text-decoration: none;
    text-align: center;
    align-items: center;
}

.links {
	display: flex;           /* Enable flexbox layout */
	justify-content: center; /* Centers content horizontally */
	align-items: center;     /* Centers content vertically */
	gap: 10px;               /* Adds space between items (optional) */
	padding-top: 40px;           /* Optional padding for some spacing */
  }

  #logo{
	width: 200px;
	height: 160px;
  }

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: rgb(0, 0, 0);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div {
    flex: 1; /* Allows the nav to take up space for centering */
}

nav {
    display: flex;
    align-items: center;
    justify-content: center; /* Centers the links horizontally */
    width: 100%; /* Makes the nav take full width */
}

.add-btn{
    
	width: 200px;
	height: 50px;
	border-bottom-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem ;
	border: 2px solid black; 
	font-size: 16px;
	background-color: transparent;
	color: black;
	
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
	}

	#logo{
		width: 140px;
		height: 120px;
		margin-left: -25px;
	}
	
	header nav {
		position: absolute;
		top: -100vh;
		left: 0;
		height: 350%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5rem;
		background-color: rgba(0, 0, 0, 0.409);
		transition: transform 1s ease-in-out; /* Adjusted for smooth opening */
		transform: translateY(0); /* Start hidden */
	}

	header .responsive_nav {
		transform: translateY(100vh); /* Brings it fully into view */
	}

	nav .nav-close-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%; /* Define a width */
	}
	
	

	nav a {
		font-size: 1rem;
		color: rgba(255, 255, 255, 0.829);
		display: flex;
	}
	.links{
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.add-btn {
		display: none;
	}
}
