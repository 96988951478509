.form{
    padding: 200px 100px 10px 100px;
}


.form-block{
    display: flex;
    justify-content: center;
    
}

.form-group{
    min-width: 600px;
    height:754px ;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 10px 15px 40px rgba(0, 0, 0, 0.25); /* More blur and lighter shadow color */

}

.form-head {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the entire form-head content horizontally */
    width: 100%; /* Ensures it takes the full width of its container */
    margin-top: 20px;
  }
  
  .form-label {
    width: 500px; /* Matches the width of the input */
    text-align: left; /* Ensures the label is left-aligned */
    margin-bottom: 10px;
    margin-top: 20px;
  }
  
  .form-control {
    border: none;
    border-bottom: 2px solid rgb(179, 179, 179); /* Gray underline */
    outline: none;
    width: 500px; /* Sets a fixed width for the input */
    padding: 5px;
    transition: border-color 0.3s ease; /* Smooth transition for hover effect */
    margin-bottom: 30px;
  }
  
  #names{
    border-bottom: 2px solid #ff7300;
  }
  .form-control:hover {
    border-bottom: 2px solid #ff7300; /* Changes the underline color on hover */
  }
  
  .form-control:focus {
    border-bottom: 2px solid #ff7300; /* Changes the underline color when focused */
  }
  
  textarea{
    width: 500px;
    margin-top: 40px;
    border: 0.5px solid rgb(179, 179, 179);
  }
  textarea:hover {
    border: 0.5px solid #ff7300; /* Changes the underline color on hover */
  }
  .submit{
    width: 350px;
    height: 50px;
    border: none;
    border-radius: 2rem;
    margin-top: 50px;
    background-color: #ff7300;
    color: white;
    font-size: 18px;
    font-weight: 500;
  }

  .submit:hover{
    background-color: #000000;
    cursor: pointer;
  }

  .form-text{
    margin-right: 50px;
  }

  .form-text h2{
    width: 500px;
    font-size: 50px;
  }

  .contact-info{
    margin-top: 80px;
  }
  .contact-info p {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  
  .contact-info p svg {
    margin-right: 15px; /* Add spacing between icon and text */
    color: #ff4400; /* Customize icon color */
    width: 30px ;
    height: 30px;
  }

  .socials svg{
    margin-top: 40px;
    color: #ff4400; /* Customize icon color */
    width: 30px;
    height: 30px;
    margin-right: 30px;
  }
  .feedback{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .error-message{
    color: red;
  }
  .success-message{
    color: rgb(0, 154, 0);
  }


  /* For screens between 1200px and 1024px (large tablets and smaller laptops) */
@media (max-width: 1200px) {
  .form {
    padding: 200px 50px 10px 50px;
  }

  .form-group {
    min-width: 500px;
  }

  .form-label, .form-control, textarea {
    width: 400px; /* Reduce width for smaller screens */
  }

  .submit {
    width: 300px; /* Smaller button size */
  }

  .form-text h2 {
    font-size: 40px; /* Adjust font size */
  }
}

@media (max-width: 1080px) {
  /* .form-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
} */

.form-group {
  min-width: 420px;
}

.form-label, .form-control, textarea {
  width: 350px; /* Reduce width for smaller screens */
}

.submit {
  width: 300px; /* Smaller button size */
}

.form-text h2 {
  font-size: 30px; /* Adjust font size */
  width: 300px;

}
.contact-info{
  margin-top: 80px;
}
.contact-info p {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 40px;
}

.contact-info p svg {
  margin-right: 15px; /* Add spacing between icon and text */
  color: #ff4400; /* Customize icon color */
  width: 25px ;
  height: 25px;
}

.socials svg{
  margin-top: 40px;
  width: 25px;
  height: 25px;
  margin-right: 30px;
}
}


@media (max-width: 798px) {
 .form-block{
    display: flex;
    flex-direction: column;
} 

}

@media (max-width: 495px) {
  .form{
    padding: 200px 10px 10px 10px;
}

  .form-group {
    min-width: 390px;
    height: 700px;
    margin-top: 50px;
  }
  
  .form-label, .form-control, textarea {
    width: 320px; /* Reduce width for smaller screens */
  }
  .form-label{
    margin-top: 0;
  }
  .submit {
    width: 270px; /* Smaller button size */
  }
   .form-text{
    margin-left: 10px;
   }

   .contact-info p {
    font-size: 15px;
   }


   


}
