@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateX(-60px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  
  
  .hero {
    max-width: 100%;
    margin-bottom: 150px;
    animation: gradient 15s ease infinite; /* Animate gradient */
    margin-top: 50px;
  }
  
  .head {
    display: flex;
    margin-left: 100px;
  }
  
  .st {
    margin-top: 100px;
  }
  
  .head #h1 {
    color: rgba(11, 27, 53, 1);
    font-size: 80px;
    font-weight: lighter;
    margin-bottom: px;
    animation: fadeInUp 1s ease forwards; /* Fade in effect */
  }
  
  .head p {
    color: rgb(0, 0, 0);
    font-size: 20px;
    margin-top: 30px;
    animation: fadeInUp 1.2s ease forwards; /* Fade in effect */
    animation-delay: 0.2s; /* Stagger effect */
  }
  
  #p1 {
    color: rgba(196, 196, 196, 1);
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: 600;
  }
  
  .btns {
    display: flex;
    margin-top: 50px;
  }
  
  .start {
    width: 162px;
    height: 49px;
    background-color: #ff7300;
    border: none;
    border-bottom-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    font-size: 18px;
    color: white;
    margin-top: 7px;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition */
  }
  
  .start:hover {
    background-color: #000000; /* Change color on hover */
    transform: translateY(-4px); /* Lift effect */
  }
  
  .learn {
    display: flex;
    margin-left: 40px;
  }
  
  .learn p {
    margin-top: 15px;
    margin-left: 10px;
    font-weight: bold;
  }
  

  @media (max-width: 1360px) {
    .head #h1 {
      color: rgba(11, 27, 53, 1);
      font-size: 65px;
      
    }

   .hero-img img{
    width: 700px;
    height:700px;
   }

   .head p {
    font-size: 17px;
    
  }

  #p1 {
    margin-bottom: 20px;
    font-size: 21px;
  }
  
  }


  @media (max-width: 1260px) {
    .head #h1 {
      font-size: 55px;
      
    }

   .hero-img img{
    width: 600px;
    height:600px;
   }

   .head p {
    font-size: 15px;
    
  }

  #p1 {
    margin-bottom: 20px;
    font-size: 18px;
  }

  }

  @media (max-width: 1000px) {
    .hero{
      justify-content: center;
      padding: 0px 100px 0px 100px ;
    }

    .head{
      margin-left: 0;
    }

    .head #h1 {
      font-size: 55px;
      text-align: center;
      
    }

   .hero-img img{
    display: none;
   }

   .head p {
    font-size: 15px;
    text-align: center;

    
  }

  #p1 {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
  }
  .btns{
    justify-content: center;
  }
  
  }

  @media (max-width: 800px) {
   .head #h1 {
      font-size: 40px;
    }
   
   .head p {
    font-size: 14px;
  }

  #p1 {
    margin-bottom: 18px;
    font-size: 16px;
  }
  
  }


  @media (max-width: 600px) {
    .head #h1 {
      font-size: 30px;
    }
   
   .head p {
    font-size: 12px;
  }

  #p1 {
    margin-bottom: 18px;
    font-size: 14px;
  }
  .learn{
    display: none;
  }
  }
  @media (max-width: 500px) {
    .hero{
      padding: 0px 50px 0px 50px ;
    }
  }
  
  
  @media (max-width: 400px) {
    .hero{
      padding: 0px 20px 0px 20px ;
    }
  }


  /* @media (max-width: 1000px) {} */


 