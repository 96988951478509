@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}
.about {
    margin-left: 80px;
    opacity: 0; /* Initially hidden */
    transform: translateY(20px); /* Move down initially */
    transition: opacity 0.8s ease, transform 0.8s ease; /* Smooth transition */
}

.about.visible {
    opacity: 1; /* Fully visible */
    transform: translateY(0); /* Move to original position */
}

.about-container {
    display: flex;
}

.about-img img {
    width: 650px;
    height: 650px;
    margin-top: 50px;
}

.about-icon {
    display: flex;
    margin-top: 55px;
    margin-bottom: 50px;
}

.about-icon img {
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease; /* Smooth transform transition */
}

.about-icon img:hover {
    transform: scale(1.1); /* Slightly enlarge icon on hover */
}

.about-icon-text {
    margin-left: 25px;
}

.about-icon-text p {
    margin-top: 10px;
    color: rgb(99, 99, 99);
}

.about-text {
    margin-left: 50px;
    margin-right: 100px;
}

.about-text h2 {
    color: rgba(11, 27, 53, 1);
    font-size: 35px;
    font-weight: 400;
    margin-top: 20px;
}

.about-text #p-1 {
    margin-top: 50px;
}

#h5 {
    color: #ff7300;
}



#h5 {
    color: #ff7300;
    animation: fadeIn 0.8s ease forwards; /* Fade-in effect */
}

  @media (max-width: 1250px) {
    .about {
        margin-left: 0px;
        padding: 0px 0px 0px 100px;
    }
    .about-img img {
        width: 560px;
        height: 560px;
        margin-top: 20px;
    }
    .about-text h2 {
        font-size: 28px;
        margin-top: 10px;
    }
    .about-text #p-1 {
        margin-top: 25px;
        font-size: 14px;
    }
    .about-icon img {
        width: 40px;
        height: 40px;
    }
    .about-icon {
        margin-top: 25px;
        margin-bottom: 30px;
    }

    .about-icon-text p {
        font-size: 14px;
    }
  }

  @media (max-width: 1025px) {
    .about {
        padding: 0px 100px 0px 100px;

        display: flex; /* Use flexbox for alignment */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
        text-align:left; /* Center text */
    }

    .about-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center children horizontally */
    }
    
    .about-img img {
        width: 460px; /* Maintain width */
        height: 460px; /* Maintain height */
        margin-top: 20px; /* Adjust top margin */
    }

    .about-text h2 {
        font-size: 22px; /* Adjust font size */
        margin-top: 10px; /* Adjust margin */
    }

    .about-text #p-1 {
        margin-top: 25px; /* Adjust margin */
        font-size: 14px; /* Adjust font size */
    }

    .about-icon img {
        display: none;
    }

    .about-icon {
        margin-top: 25px; /* Adjust margin */
        margin-bottom: 30px; /* Adjust margin */
        justify-content: center; /* Center icon content */
        display: flex; /* Use flexbox */
        align-items: center; /* Center icons and text */
    }

    .about-icon-text p {
        font-size: 14px; /* Adjust font size */
    }

    .about-text {
        margin-left: 0; /* Remove left margin */
        margin-right: 0; /* Remove right margin */
        padding: 0 40px; /* Add some padding */
        margin-top: 30px;
    }
    .about-icon-text {
        margin-left: 0;
    }

}


@media (max-width: 500px) {
    .about-img img {
        width: 380px; /* Maintain width */
        height: 380px; /* Maintain height */
        margin-top: 20px; /* Adjust top margin */
    }
    .about-text h2 {
        font-size: 18px; /* Adjust font size */
        margin-top: 10px; /* Adjust margin */
    }

    .about-text {
        margin-left: -10px;
    }
}