@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}

.gallary{
    padding: 0px 100px 0px 100px;
    margin-top: 200px;
}
.gallary-container {
    
    display: flex;
    flex-direction: column; /* Stack the content vertically */
    align-items: center; /* Center horizontally */
    text-align: center; /* Center the text inside the elements */
}

.gala-head h4, .gala-head p {
    text-align: center; /* Centers text within h5 and p */
}

.gala-head h4{
    font-size: 24px;
    font-weight: 400;
    color: rgba(11, 27, 53, 1);

}

.gala-head p{
    color:rgb(99, 99, 99);
    margin-top: 10px;
    margin-bottom: 70px;
}

.gallary-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallary-item, .gallary-item2 {
    display: flex;
    justify-content: center;
}

.gallary-item img, .gallary-item2 img {
    width: 416px;
    height: 416px;
    margin-left: 12px;
    margin-top: 12px;
    border-radius: 8px;
}
.gallary-item img:hover, 
.gallary-item2 img:hover {
    transform: translateY(-8px); /* Lift effect */
    transition: transform 0.2s ease-in-out; /* Smooth transition */
}


#flags {
    width: 844px;
    height: 500px;
    margin-left: 12px;
    border-radius: 8px;
}



@media (max-width: 1300px) {
    .gallary{
        padding: 0px 100px 0px 100px;
        margin-top: 200px;
    }
    .gallary-item img {
        width: 350px;
        height: 350px;
    }
    .gallary-item2 img {
        width: 350px;
        height: 450px;
       
    }
    #flags{
        width: 712px;
        height: 450px;
    }
}

@media (max-width: 1000px) {
    .gallary{
        padding: 0px 100px 0px 100px;
        margin-top: 150px;
    }
    .gallary-item img {
        width: 280px;
        height: 280px;
    }
    .gallary-item2 img {
        width: 280px;
        height: 350px;
       
    }
    #flags{
        width: 572px;
        height: 350px;
    }
}

@media (max-width: 800px) {
    .gallary{
        padding: 0px 100px 0px 100px;
        margin-top: 150px;
    }
    .gallary-item img {
        width: 200px;
        height: 200px;
    }
    .gallary-item2 img {
        width: 200px;
        height: 220px;
       
    }
    #flags{
        width: 412px;
        height: 220px;
    }
}

@media (max-width: 600px) {
    .gallary {
        padding: 0;
        margin-top: 150px;
        display: flex;
        overflow-x: auto;
        flex-direction: column; /* Ensure all items are inline */
    }
    .gala-head {
        position: absolute;
        margin-top: -260px;
        padding: 0 50px 0 50px;
    }

    .gala-head h4{
        font-size: 16px;
    
    }
    
    .gala-head p{
        margin-top: 10px;
        margin-bottom: 70px;
        font-size: 13px;
    }

    .gallary-container {
        width: 100%; /* Full width for scrolling */
        display: flex;
        flex-direction: row; /* Inline layout */
        overflow-x: auto; /* Enable horizontal scrolling */
        scroll-snap-type: x mandatory; /* Slider snap */
    }
    .gallary-grid{
        display: flex;
        flex-direction: row;
        margin-top: 80px;
    }

    .gallary-item, .gallary-item2 {
        scroll-snap-align: start; /* Snaps each item into view */
    }

    .gallary-item img, .gallary-item2 img {
        width: 200px;
        height: 200px; /* Adjust size for mobile */
    }

    .gallary-item2 img {
        height: 200px; /* Special height for this item */
    }

    #flags {
        width: 300px;
        height: 200px; /* Adjust for mobile */
    }
}
