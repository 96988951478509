.footer-section{
    background-color:rgb(118, 118, 118);
    height: 300px;
}

.footer-wrapper {
    margin: 6rem 0rem;
    display: flex;
    margin-top: 10rem;
    background-color: rgb(44, 36, 29);
    padding: 50px 100px 100px 100px;
  }
  .footer-logo-container {
    max-width: 110px;
  }
  .footer-icons {
    margin-top: 2.5rem;
  }
  .footer-icons svg {
    font-size: 1.5rem;
    margin-right: 1.25rem;
    color: #ff7300;
  }
  /* .footer-section-one {
    border: 2px solid blue;
  } */
  .footer-section-two {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .footer-section-columns {
    display: flex;
    flex-direction: column;
    min-width: 190px;
  }
  .footer-section-columns span {
    margin: 0.25rem 0rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
    cursor: pointer;
  }

  @media (max-width: 800px) {
    .footer-wrapper {
        flex-direction: column;
      }
      .footer-section-two {
        flex-direction: column;
        margin-top: 2rem;
      }
      .footer-section-columns {
        margin: 1rem 0rem;
      }
  }
