.more{
    margin-left: 140px;
    margin-right: 0px;
    margin-top: 150px;
}

.more-title{
    margin-top: 60px;
    width: 300%;
}

.more-title h4{
    color: rgba(11, 27, 53, 1);
    font-size: 40px;
    font-weight:400;
    margin-bottom: 50px;
}

.more-title p{
    margin-bottom: 40px;
}


.more-container{
    display: flex;
    

}

.merge{
    display: flex;
}



.more-icon{
    display: flex;
    margin-top: 55px;
    margin-bottom: 50px;
}

.more-icon img{
    width: 34px;
    height: 34px;
}

.more-icon-text{
    margin-left: 20px;
}

.more-icon-text p{
    margin-top: 25px;
    color: rgb(99, 99, 99);
}

.more-icon-text h4{
    color: rgba(11, 27, 53, 1);
    font-size: 18px;
    margin-top: 4px;
    margin-left: 10px;
}




.more-text{
    margin-left: 50px;
    margin-right: 100px;
}

.more-text h2{
    color: rgba(11, 27, 53, 1);
    font-size: 35px;
    font-weight:400;
    margin-top: 20px;

}

.more-text #p-1{
    margin-top: 50px;
}
#h5{
    color:#ff7300 ;
}

/* For tablets (max-width: 1100px) */
@media (max-width: 1100px) {
    .more {
        margin-left: 80px;
        margin-right: 20px;
        margin-top: 100px;
    }

    .more-title h4 {
        font-size: 35px;
    }

    .more-text h2 {
        font-size: 30px;
    }

    .more-icon img {
        width: 28px;
        height: 28px;
    }

    .more-text {
        margin-left: 30px;
        margin-right: 60px;
    }
}

/* For small tablets and large phones (max-width: 768px) */
@media (max-width: 1025px) {
    .more {
        margin-left: 40px;
        margin-right: 10px;
        margin-top: 80px;
    }
.b{
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
    .more-title h4 {
        font-size: 28px;
    }

    .more-text h2 {
        font-size: 25px;
    }

    .more-container {
        flex-direction: column;
    }

    .merge {
        flex-direction: column;
    }

    .more-icon {
        margin-top: 30px;
    }

    .more-icon img {
        display: none;
    }

    .more-text {
        margin-left: 0px;
        margin-right: 30px;
    }
    .more-title{
        margin-top: 60px;
        width: 100%;
    }
}

/* For mobile phones (max-width: 480px) */
@media (max-width: 480px) {
    .more {
       
        margin-top: 50px;
        padding: 60px 0px 60px 0px;
    }

    .more-title h4 {
        font-size: 22px;
    }

    .more-text h2 {
        font-size: 18px;
    }

    .more-icon img {
        width: 20px;
        height: 20px;
    }

    .more-icon-text p {
        font-size: 12px;
    }

    .more-icon-text h4{
        font-size: 16px;
        margin-top: 4px;
        margin-left: 0px;
    }

    .more-text {
        margin-left: 0px;
    }
    .more-icon{
        display: flex;
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 0px;
       
    }
    .more-icon-text{
        margin-left: 0px;
        margin-top: 40px;
    }

    #h5 {
        font-size: 14px;
    }
}
