@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}
.page{
    padding: 250px 100px 100px 100px;
}
.bt{
    margin-bottom: 12px;
    margin-top: 50px;
}

.page-container{
    display: flex;
    
}

.page-img{
    width: 100%;
    display:flex ;
    align-items: center;
    justify-content: center;
}
.page-img img{
    width: 700px;
    height: 400px;
    margin-top: 50px;
}

.page-icon{
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
}

.page-icon img{
    width: 50px;
    height: 50px;
}

.page-icon-text{
    margin-left: 25px;
    
}

.page-icon-text p{
    margin-top: 10px;
    color: rgb(99, 99, 99);
}



.page-text{
    margin-left: 50px;
    margin-right: 100px;
}

.page-text h2{
    color: rgba(11, 27, 53, 1);
    font-size: 35px;
    font-weight:400;
    margin-top: 20px;

}

.page-text #p-1{
    margin-top: 20px;
}
#p-1{
    margin-bottom: 25px;
}
#p-2{
    font-weight: 600;
    margin-bottom: 10px;
}

#h5{
    color:#ff7300 ;
}

@media (max-width: 1440px) {
    .page-img img{
        width: 650px;
        height: 350px;
    }
}

@media (max-width: 1400px) {
    .page-img img{
        width: 650px;
        height: 350px;
    }

    
    .page-text{
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media (max-width: 1280px) {
    .page-container{
        display: flex;
        flex-direction: column;
    }
    
    .page{
        padding: 100px 50px 0px 50px;
    }
}

@media (max-width: 700px) {
    .page-container{
        display: flex;
        flex-direction: column;
    }
    
    .page{
        padding: 100px 50px 0 50px;
    }

    .page-img img{
        width: 450px;
        height: 250px;
        justify-content: center;
    }
    .page-text{
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (max-width: 500px) {
    .page-img img{
        width: 380px;
        height: 200px;
    }
}